$zmdi-var-close: '\f136' !default;
$zmdi-var-chevron-down: '\f2f9' !default;

.#{$zmdi-icon-prefix}-close {
	&:before {
		content: $zmdi-var-close;
	}
}

.#{$zmdi-icon-prefix}-chevron-down {
	&:before {
		content: $zmdi-var-chevron-down;
	}
}