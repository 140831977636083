// Font import
@import "assets/font_import";

// Iconic font import
@import 'assets/variables';
@import 'assets/path';
@import 'assets/core';
@import 'assets/icons';

// Normalize
@import "../../bower_components/normalize.css/normalize.css";

/*Vex popup*/
@import "plugins/vex.css";
@import "plugins/vex-theme-default.css";

/*Slick*/
@import "../../bower_components/slick-carousel/slick/slick.css";
// @import "../../bower_components/slick-carousel/slick/slick-theme.css";

// Colors
$red: #ee2427;
$black: #393a3c;
$blue: #254689;

// Fonts
$bebas: AABebasNeue;
$arimo: Arimo;
$arimo-it: Arimo-Italic;
$arimoB: Arimo-Bold;
$arimoB-i: Arimo-BoldItalic;

// Transitions
$main: .2s cubic-bezier(0.217, 0.59, 0.354, 1);

// Media
$min1100: "only screen and (min-width: 1100px)";

///// GENERAL STYLES /////
* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.main__wrapper {
	min-width: 1100px;
}

img {
	width: 100%;
}

p, h1, h2 {
	margin: 0;
}

.section__headings {
	display: block;
	font: 28px $arimoB;
	color: #393a3c;
	text-align: center;
	text-transform: uppercase;
}

a {
	outline: none;
	text-decoration: none;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

select {
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

textarea {
	resize: none;
}

input,
textarea {
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	transition: $main;
	&.error {
		// border: solid $red 2px !important;
		border-color: $red !important;
		-webkit-box-shadow: inset 0px 0px 0px 1px $red;
		-moz-box-shadow: inset 0px 0px 0px 1px $red;
		box-shadow: inset 0px 0px 0px 1px $red;
	}
}

button {
	outline: none;
	border: none;
	cursor: pointer;
	transition: $main;
}

%clearfix {
	&:after {
		content: "";
		width: 100%;
		display: table;
		clear: both;
	}
}

%content-size {
	width: 1100px;
	margin: 0 auto;
	padding: 0 80px;
}

// Slider primary styles !!! CSS core (Should not edit) !!!
.lSSlideOuter {
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}
.lightSlider:before, .lightSlider:after {
    content: " ";
    display: table;
}
.lightSlider {
    overflow: hidden;
    margin: 0;
}
.lSSlideWrapper {
    max-width: 100%;
}
.lSSlideWrapper > .lightSlider:after {
    clear: both;
}
.lSSlideWrapper .lSSlide {
    -webkit-transform: translate(0px, 0px);
    -ms-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
    -webkit-transition: all 1s;
    -webkit-transition-property: -webkit-transform,height;
    -moz-transition-property: -moz-transform,height;
    transition-property: transform,height;
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important;
}
.lSSlideWrapper .lSFade {
    position: relative;
}
.lSSlideWrapper .lSFade > * {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 9;
    margin-right: 0;
    width: 100%;
}
.lSSlideWrapper.usingCss .lSFade > * {
    opacity: 0;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important;
}
.lSSlideWrapper .lSFade > *.active {
    z-index: 10;
}
.lSSlideWrapper.usingCss .lSFade > *.active {
    opacity: 1;
}

// Slider actions
.lSAction > a {
	position: absolute;
	display: block;
	background-image: url('../img/sprite.png');
	background-repeat: no-repeat;
	opacity: 1;
	z-index: 99;
	transition: opacity 0.35s linear 0s;
	cursor: pointer;
	&:hover {
		opacity: .8;
	}
}

.lSAction > a.disabled {
	pointer-events: none;
}
.cS-hidden {
	height: 1px;
	opacity: 0;
	filter: alpha(opacity=0);
	overflow: hidden;
}

// Slider gallery
.lSSlideOuter .lSPager.lSGallery {
    list-style: none outside none;
    padding-left: 0;
    margin: 0;
    overflow: hidden;
    transform: translate3d(0px, 0px, 0px);
    -moz-transform: translate3d(0px, 0px, 0px);
    -ms-transform: translate3d(0px, 0px, 0px);
    -webkit-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate3d(0px, 0px, 0px);
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.lSSlideOuter .lSPager.lSGallery li {
	height: 78px;
	a {
		display: block;
	}
	img {
		display: block;
	}
}
.lSSlideOuter .lSPager.lSGallery:before, .lSSlideOuter .lSPager.lSGallery:after {
    content: " ";
    display: table;
}
.lSSlideOuter .lSPager.lSGallery:after {
    clear: both;
}

// Slider Rtl
.lSSlideOuter.lSrtl {
	direction: rtl;
}
.lSSlideOuter .lightSlider, .lSSlideOuter .lSPager {
	padding-left: 0;
	list-style: none outside none;
}
.lSSlideOuter.lSrtl .lightSlider, .lSSlideOuter.lSrtl .lSPager {
	padding-right: 0;
}
.lSSlideOuter .lightSlider > *,  .lSSlideOuter .lSGallery li {
	float: left;
}
.lSSlideOuter.lSrtl .lightSlider > *,  .lSSlideOuter.lSrtl .lSGallery li {
	float: right !important;
}

.main__wrapper {
	&.main {
		.banner__slider {
			max-height: 100%;
		}
		header {
			.header__logo {
				span {
					background-image: url('../img/logo.png');
				}
				p {
					color: #fff;
				}
			}
			.header__nav {
				li {
					a {
						color: #fff;
					}
				}
			}
			.header__phone {
				a {
					color: #fff;
					background-position: 0 0;
				}
				button {
					color: #fff;
				}
			}
			@media #{$min1100} {
				.header__nav {
					.drop-down__wrapper {
						&:hover {
							.drop-down__menu {
								border-top: solid $red 1px;
							}
						}
					}
				}
				&.fixed {
					// background: rgb(37,70,177);
					background: #254689;
					.header__nav {
						.nav-link {
							&.drop-down__wrapper {
								&:hover {
									> a {
										color: #fff !important;
										border: solid #fff 1px;
									}
									.drop-down__menu {
										border-top: none;
									}
								}
							}
							> a {
								&.active,
								&:hover {
									color: #fff !important;
									border: solid #fff 1px;
								}
							}
						}
					}
					.header__phone {
						button {
							&:hover {
								color: #fff !important;
							}
						}
					}
				}
			}
		}
		.lSSlideOuter {
			padding-top: 0;
			.banner-slider {
				.banner-slide {
					min-height: 600px;
					padding-top: 73px;
					> div {
						padding-top: 70px;
					}
				}
			}
			.lSAction > a {
				bottom: 275px;
			}
		}
	}
}

.banner__slider {
	position: relative;
}

header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 73px;
	z-index: 1;
	transition: $main;
	.header__wrapper {
		position: relative;
		display: block;
		height: 100%;
		@extend %content-size;
		li {
			display: inline-block;
		}
		@extend %clearfix;
	}
	.header__logo {
		position: absolute;
		top: 0;
		left: 80px;
		a {
			display: block;
			padding-top: 6px;
		}
		span {
			display: block;
			width: 219px;
			height: 61px;
			background-image: url('../img/logo-blue.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: 0 0;
			transition: $main;
		}
		p {
			position: absolute;
			right: -21px;
			bottom: -22px;
			display: inline-block;
			font: 12px/14px $arimoB;
			color: #393a3c;
			opacity: 1;
			visibility: visible;
			text-transform: uppercase;
			transition: $main;
		}
	}
	.header__nav {
		float: left;
		.nav-link__wrapper {
			display: inline-block;
			@extend %clearfix;
			line-height: 73px;
			padding-left: 274px;
			transition: $main;
		}
		.nav-link {
			position: relative;
			margin-left: 3px;
			&.drop-down__wrapper {
				&:hover {
					> a {
						color: $red !important;
						border: solid $red 1px;
					}
					.drop-down__menu {
						display: block;
					}
					i {
						transform: rotate(-180deg) translate(0, -1px);
					}
				}
			}
			> a {
				position: relative;
				z-index: 1;
				display: block;
				font: 14px/32px $arimoB;
				color: #393a3c;
				border: solid 1px transparent;
				text-transform: uppercase;
				padding: 0 8px;
				transition: $main;
				&.active {
					color: $red !important;
					border: solid $red 1px;
					&:hover {
						i {
							transform: rotate(-180deg) translate(0, -1px);
						}
					}
				}
				&:hover {
					color: $red !important;
					border: solid $red 1px;
				}
			}
			.drop-down__menu {
				position: absolute;
				z-index: 0;
				top: 33px;
				right: 0;
				display: none;
				box-shadow: 0px 25px 7px 0px rgba(0,0,0,0.11);
				background: #fff;
				padding: 0;
				transition: $main;
				padding: 16px 0;
				li {
					display: block;
					background: #fff;
					a {
						display: block;
						font: 14px/18px $arimo;
						color: $black;
						padding: 6px 19px;
						transition: $main;
						&.active,
						&:hover {
							color: #fff;
							background: $red;
						}
					}
				}
				&.visible {
					display: inline-block;
				}
			}
			&:nth-of-type(2) {
				padding-right: 0;
				a {
					position: relative;
					padding: 0 24px 0 8px;
					i {
						position: absolute;
						right: 9px;
						top: 3px;
						font-size: 25px;
					}
				}
			}
		}
	}
	.header__phone {
		position: relative;
		top: 25px;
		float: right;
		text-align: right;
		transition: $main;
		a {
			display: block;
			font: 21px/21px $arimoB;
			color: #393a3c;
			background-image: url('../img/sprite.png');
			background-repeat: no-repeat;
			background-position: 0 -24px;
			background-size: 20px 224px;
			padding-left: 32px;
			transition: $main;
		}
		button {
			font: 14px/34px $arimoB;
			color: #393a3c;
			text-transform: uppercase;
			background: transparent;
			padding: 0;
			transition: $main;
			&:hover {
				color: $red !important;
			}
		}
	}
	@media #{$min1100} {
		&.fixed {
			position: fixed;
			z-index: 100;
			height: 63px;
			background: #fff;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.09);
			.header__logo {
				span {
					width: 180px;
				}
				p {
					opacity: 0;
					visibility: hidden;
				}
			}
			.header__nav {
				ul {
					line-height: 63px;
				}
			}
			.header__phone {
				top: 11px;
				a {
					font-size: 18px;
				}
				button {
					line-height: 24px;
				}
			}
		}
	}
}

// Banner slider controls styles
.banner__slider {
	max-height: 531px;
	.lSSlideOuter {
		padding-top: 120px;
	}
	.lSSlideWrapper {
		position: relative;
	}
	.lSAction {
		position: absolute;
		right: 0;
		left: 0;
		@extend %content-size;
	}
	.lSAction > a {
		bottom: 205px;
		width: 22px;
		height: 39px;
		background-size: 22px 246px;
		margin-top: -13.5px;
	}
	.lSAction > .lSPrev {
		background-position: 0 -54px;
		left: -82px;
	}
	.lSAction > .lSNext {
		background-position: 0 -98px;
		right: -82px;
	}
}

.banner-slider {
	.banner-slide {
		position: relative;
		min-height: 411px;
		background-size: cover;
		background-repeat: no-repeat;
		padding-top: 38px;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(0,0,0,.25);
		}
		> div {
			position: relative;
			z-index: 1;
			@extend %content-size;
			font-size: 0;
			padding-top: 0;
		}
		h1, h2 {
			color: #fff;
		}
		.banner__headings-1 {
			display: inline-block;
			font: 69px/59px $bebas;
			text-transform: uppercase;
			vertical-align: top;
		}
		.banner__headings-2 {
			font: 28px/30.5px $arimoB;
			text-transform: uppercase;
			padding-bottom: 30px;
		}
		ul {
			position: relative;
			top: -5px;
			display: inline-block;
			padding-left: 28px;
			li {
				font: 22px/1 $arimo;
				color: #fff;
				text-transform: uppercase;
				padding-bottom: 10px;
			}
			&.listed {
				list-style-type: disc;
			}
		}
		button {
			display: block;
			min-width: 200px;
			font: 16px/45px $arimoB;
			color: #fff;
			border: solid #fff 1px;
			text-transform: uppercase;
			background: transparent;
			padding: 0 10px;
			&:hover {
				border-color: $red;
				background: $red;
			}
		}
	}
}

.banner-page-label {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	background: rgba(0,0,0,.4);
	padding-top: 14px;
	span {
		@extend %content-size;
		display: block;
		font: 55.8px/48px $bebas;
		color: #fff;
	}
}

.banner-form__wrapper {
	position: absolute;
	top: 91px;
	right: 0;
	width: 100%;
	.banner-form {
		@extend %content-size;
		text-align: right;
		> div {
			display: inline-block;
			width: 100%;
			max-width: 301px;
			text-align: left;
			background: $blue;
			h2 {
				display: block;
				font: 18px/22px $arimoB;
				color: #fff;
				text-align: center;
				text-transform: uppercase;
				padding: 13px 40px;
			}
			form {
				background: #fff;
				padding: 20px 15px 2px;
				div {
					margin-bottom: 5px;
					label {
						display: block;
						font: 14px $arimoB;
						text-transform: uppercase;
					}
					input,
					textarea {
						display: block;
						width: 100%;
						border: solid 1px #bdbdbd;
						padding: 0 15px;
					}
					input {
						height: 42px;
					}
					textarea {
						height: 90px;
						padding-top: 10px;
						padding-bottom: 10px;
					}
				}
			}
			button {
				display: block;
				width: 100%;
				max-width: 200px;
				font: 14px/45px $arimoB;
				color: #fff;
				text-transform: uppercase;
				border: solid #fff 1px;
				background: transparent;
				padding: 0;
				margin: 15px auto;
				&:hover {
					background: #fff;
					color: $blue;
				}
			}
		}
	}
}

.banner-description__wrapper {
	background: #f1f1f1;
	padding: 40px 0;
	.banner-description {
		@extend %content-size;
	}
	h4 {
		margin: 0;
		font-family: $arimoB-i;
		font-size: 18px;
		font-style: italic;
		padding-bottom: 20px;
	}
	p {
		font: 16px $arimo;
		text-indent: 20px;
		text-align: justify;
		padding-bottom: 10px;
	}
}

.contacts__main-wrapper {
	background: #254689;
	padding: 80px 0;
	.contacts-wrapper {
		@extend %content-size;
		> div {
			float: left;
			color: #fff;
			&.map {
				width: 34.3%;
				padding-right: 22px;
			}
			&.requisites {
				width: 34%;
			}
			&.contacts {
				width: 31.7%;
			}
		}
		h1 {
			font: 22px $arimoB;
			text-transform: uppercase;
			padding-bottom: 11px;
		}
		p {
			display: block;
			font: 14px/22px $arimo;
			margin-bottom: 6px;
			span {
				font-family: $arimoB;
			}
			a {
				color: #fff;
				text-decoration: underline;
			}
		}
		ul {
			font: 14px/24px $arimo;
			padding: 12px 0;
			li {
				display: block;
			}
		}
		#map {
			height: 284px;
		}
		#map-marker-content {
			color: #000;
		}
		@extend %clearfix;
	}
}

footer {
	height: 102px;
	background: #2e2e33;
	text-align: center;
	padding-top: 19px;
	img {
		display: inline-block;
		width: 161px;
	}
}
///// END OF GENERAL STYLES /////


///// MAIN PAGE STYLES /////
.services__wrapper {
	@extend %content-size;
	h1 {
		padding: 75px 0 52px;
	}
	.services {
		padding-bottom: 58px;
		margin-right: -19px;
		> li {
			display: block;
			width: 25%;
			float: left;
			padding-right: 19px;
			&.services__1 {
				div {
					padding-top: 42px;
				}
			}
			&.services__2 {
				div {
					padding-top: 25px;
				}
			}
			&.services__3 {
				div {
					padding-top: 39px;
				}
			}
			&.services__4 {
				div {
					padding-top: 31px;
				}
			}
			> a {
				position: relative;
				display: block;
				text-align: center;
				padding-bottom: 46px;
				div {
					height: 120px;
				}
				svg {
					fill: rgb(57, 58, 60);
					transition: $main;
				}
				h2 {
					font: 28px/1 $bebas;
					color: #254689;
					text-transform: uppercase;
					transition: $main;
				}
				&:after {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					border: solid 1px #fff;
					transition: $main;
				}
				&:hover {
					h2 {
						color: $red;
					}
					svg {
						fill: $red;
					}
					&:after {
						border: solid 1px $red;
					}
				}
			}
		}
		@extend %clearfix;
	}
}

.form__main-wrapper {
	min-height: 260px;
	&.form-1 {
		background: url('../img/form-bg-1.jpg') no-repeat top center/cover;
	}
	&.form-2 {
		background: url('../img/form-bg-2.jpg') no-repeat top center/cover;
	}
	.form__wrapper {
		@extend %content-size;
		.form__headings {
			width: 65%;
			float: left;
			padding-top: 30px;
			span {
				display: block;
				font: 18px/20px Arimo;
				text-indent: 20px;
			}
		}
		.form__fields {
			width: 35%;
			float: right;
			text-align: right;
			padding-top: 20px;
		}
		h1 {
			font: 48px/40px $bebas;
			color: $black;
			padding-bottom: 15px;
		}
		form {
			display: inline-block;
			width: 100%;
			max-width: 300px;
			color: $black;
			text-align: left;
			label {
				display: block;
				font: 16px $arimoB;
				text-transform: uppercase;
			}
			input {
				display: block;
				width: 100%;
				height: 42px;
				border: solid 1px #bdbdbd;
				padding: 0 15px;
				margin-bottom: 22px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		button {
			font: 16px/42px $arimoB;
			color: #fff;
			text-transform: uppercase;
			background: $red;
			padding: 0 16px;
			margin-top: 17px;
			&:hover {
				background: #dc090c;
			}
		}
		@extend %clearfix;
	}
}

.about__wrapper {
	h1 {
		padding: 36px 0 58px;
	}
	.about {
		display: table;
		width: 100%;
		> div {
			display: table-row;
			> div {
				display: table-cell;
				width: 50%;
				vertical-align: top;
				> div {
					display: inline-block;
					max-width: 550px;
				}
			}
		}
		.about__text {
			color: #fff;
			background: #254689;
			text-align: right;
			div {
				text-align: left;
				padding: 53px 25px 53px 80px;
			}
			p {
				font: 16px/26px $arimo;
				text-align: justify;
				margin-bottom: 26px;
				text-indent: 20px;
			}
		}
		.about__list {
			div {
				padding: 0 80px 0 10px;
			}
			h2 {
				font: 18px $arimoB;
				color: $black;
				padding-bottom: 10px;
			}
			li {
				display: inline-block;
				font: 16px/20px $arimo;
				color: $black;
				background: url('../img/sprite.png') no-repeat 3px -174px/17px 190px;
				padding-left: 32px;
				margin: 8px 0 4px;
			}
		}
	}
}

.partners__wrapper {
	@extend %content-size;
	h1 {
		padding: 55px 0 21px;
	}
	.slick-arrow {
		position: absolute;
		top: 50%;

		display: block;
		background-color: transparent;
		background-image: url(../img/sprite.png);
    	background-repeat: no-repeat;
	    background-size: 22px 246px;

		width: 22px;
		height: 39px;
		padding: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, -50%);

		cursor: pointer;

		color: transparent;
		border: none;
		outline: none;
		&.slick-prev {
			left: -40px;
		    background-position: 0 -54px;
		}
		&.slick-next {
			right: -40px;
		    background-position: 0 -98px;
		}
	}
	.slick-track {
		height: 158px;
	}
	.partners {
		padding-bottom: 30px;
		li {
			margin: 0 15px;
			> div {
				display: table;
				width: 100%;
				height: 100%;
				> div {
					display: table-cell;
					vertical-align: middle;
				}
			}
			img {
				display: block;
				opacity: .7;
				filter: grayscale(100%);
				transition: $main;
				vertical-align: middle;
				&:hover {
					opacity: 1;
					filter: grayscale(0);
				}
			}
		}
	}
}

// Partners slider controls styles
.partners__slider {
	// position: relative;
	// .lSSlideOuter {
	// 	// padding-top: 120px;
	// }
	// .lSSlideWrapper {
	// 	position: relative;
	// }
	// .lSAction {
	// 	position: absolute;
	// 	right: 0;
	// 	left: 0;
	// 	@extend %content-size;
	// }
	// .lSAction > a {
	// 	bottom: 0 !important;
	// 	width: 22px;
	// 	height: 39px;
	// 	// background-size: 22px 246px;
	// 	// margin-top: -13.5px;
	// }
	// .lSAction > .lSPrev {
	// 	background-position: 0 -54px;
	// 	left: -82px;
	// }
	// .lSAction > .lSNext {
	// 	background-position: 0 -98px;
	// 	// right: -82px;
	// }
}

.offers__wrapper {
	h1 {
		padding: 33px 0;
	}
	.offers__table {
		display: table;
		width: 100%;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
		> div {
			display: table-row;
			> div {
				display: table-cell;
				width: 50%;
				vertical-align: top;
				> div {
					display: inline-block;
					width: 100%;
					max-width: 550px;
					text-align: left;
				}
			}
			.offers__tabs {
				text-align: right;
				> div {
					padding: 0 0 0 80px;
					> div {
						padding: 41px 0 41px 80px;
						> a {
							display: block;
							margin-bottom: 59px;
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
			.offers__text {
				background: rgba(0,0,0,.2);
				> div {
					display: none;
					padding: 24px 80px 13px 29px;
					&.active {
						display: block;
					}
				}
				p {
					display: inline-block;
					font: 16px/22px $arimo;
					color: #fff;
					text-align: justify;
					text-indent: 20px;
					margin-bottom: 10px;
				}
				ul {
					font: 16px/22px $arimo;
					color: #fff;
					list-style-type: disc;
					margin-bottom: 10px;
					padding-left: 20px;
				}
				button {
					font: 16px/42px $arimoB;
					color: #fff;
					text-transform: uppercase;
					background: $red;
					padding: 0 27px;
					margin-top: 25px;
					&:hover {
						background: #dc090c;
					}
				}
			}
		}
	}
	.offers__tab {
		font-size: 0;
		&.active,
		&:hover {
			div {
				background: #fff;
				svg {
					path {
						fill: $red;
					}
				}
			}
			span {
				color: $red;
			}
		}
		div {
			position: relative;
			display: inline-block;
			width: 120px;
			height: 120px;
			background: $red;
			vertical-align: middle;
			transition: $main;
			svg {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				transition: $main;
			}
		}
		span {
			display: inline-block;
			font: 28px/1 $bebas;
			color: #fff;
			vertical-align: middle;
			transition: $main;
			margin-left: 30px;
		}
	}
}

.logistics__wrapper {
	@extend %content-size;
	h1 {
		position: relative;
		display: block;
		font: 48px/40px $bebas;
		color: #262626;
		margin: 70px 0 20px 0;
		&:after {
			content: '';
			position: absolute;
			bottom: 9px;
			right: 0;
			width: 654px;
			height: 4px;
			background: #d7d7d7;
		}
	}
	.logistics__slider {
		padding-bottom: 70px;
		> div {
			float: left;
		}
		.logistics-slider__text {
			width: 51.063829787234043%;
			padding: 17px 50px 0 0;
			p {
				display: inline-block;
				font: 16px/20px $arimo;
				text-indent: 20px;
				text-align: justify;
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.logistics-slider__wrapper {
			position: relative;
			width: 48.936170212765957%;
			span {
				position: relative;
				top: -3px;
				display: block;
				font: 22px/1 $arimo;
				color: #262626;
				text-align: center;
				padding-bottom: 6px;
			}
			.logistics-slider {
				li {
					height: 247px;
				}
			}
			li {
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 0 0;
			}
			// Banner slider controls styles
			.lSAction {
				position: absolute;
				right: 0;
				left: 0;
			}
			.lSAction > a {
				bottom: -60px;
				width: 12px;
				height: 22px;
				background-size: 12px 120px;
			}
			.lSAction > .lSPrev {
				background-position: 0 -68px;
				left: -15px;
			}
			.lSAction > .lSNext {
				background-position: 0 -88px;
				right: -15px;
			}
		}
		@extend %clearfix;
	}
}
///// END OF MAIN PAGE STYLES /////


///// INSIDE PAGE STYLES /////
.main__wrapper {
	&.avia {
		.section__headings {
			padding-top: 25px;
		}
	}
}

.auto-advantages__wrapper {
	padding: 23px 0 29px;
	.auto-advantages {
		@extend %content-size;
	}
	.section__headings {
		padding: 65px 0 28px;
	}
	ul {
		li {
			position: relative;
			margin-bottom: 20px;
		}
		.number__wrapper,
		h2 {
			display: inline-block;
			vertical-align: middle;
		}
		h2 {
			font: 22px $arimoB;
			color: #393a3c;
			padding-left: 60px;
		}
		.number__wrapper {
			position: absolute;
			left: 0;
			width: 40px;
			height: 40px;
			text-align: center;
			border-radius: 40px;
			background: $blue;
			span {
				position: relative;
				left: 2px;
				display: inline-block;
				font: 22px/40px $arimoB;
				color: #fff;
			}
		}
	}
}

.auto-services__wrapper {
	background: $blue;
	padding: 34px 0 8px;
	.auto-services {
		@extend %content-size;
		> h1 {
			display: block;
			font: 28px $arimoB;
			color: #fff;
			text-align: center;
			text-transform: uppercase;
			padding-bottom: 45px;
		}
		ul {
			li {
				display: table;
				width: 50%;
				height: 110px;
				float: left;
				margin-bottom: 50px;
				&:nth-of-type(2n+1) {
					clear: both;
				}
				&:nth-of-type(2n+2) {
					padding-left: 15px;
				}
				> div {
					display: table-cell;
					
				}
				.auto-services__title {
					width: 150px;
					font: 26px/24px $bebas;
					color: $red;
					text-align: center;
					text-transform: uppercase;
					padding-right: 15px;
					// vertical-align: bottom;
					vertical-align: middle;
					.auto-services__icon {
						padding-bottom: 5px;
					}
					> span {
						display: block;
						path {
							fill: $red;
						}
					}
				}
				.auto-services__text {
					font: 18px/20px $arimo;
					color: #fff;
					padding-left: 7px;
					vertical-align: middle;
				}
			}
			@extend %clearfix;
		}
		.extra {
			text-align: center;
			padding: 10px 0 30px;
			p {
				font: 18px/20px $arimo;
				color: #fff;
				span {
					font: 24px/36px $arimoB;
				}
				button {
					font-family: $arimoB;
					color: $red;
					background: none;
					padding: 0;
				}
			}
		}
	}
}

.multi__table-wrapper {
	.multi-table {
		@extend %content-size;
		padding: 40px;
		h1 {
			padding-bottom: 20px;
		}
		table {
			width: 100%;
			border-collapse: collapse;
    		border-spacing: 0;
		}
		td {
			font-family: $arimo;
			font-size: 16px;
			border: solid #e1e1e1 2px;
			padding: 10px 20px;
			vertical-align: middle;
			&.term {
				width: 15%;
				color: $red;
			}
			span {
				font-family: $arimoB;
				font-size: 18px;
			}
		}
		h2 {
			font: 24px $arimo;
			color: #393a3c;
			text-align: center;
			padding-bottom: 20px;
			span {
				font-family: $arimoB;
			}
		}
		ul {
			padding: 40px 0;
			margin-left: -30px;
			li {
				width: 25%;
				float: left;
				padding-left: 30px;
				.number {
					font: 28px/1 $arimoB;
					width: 30px;
					color: $red;
					float: left;
				}
				.text {
					font: 16px $arimo;
					overflow: hidden;
				}
				@extend %clearfix;
			}
			@extend %clearfix;
		}
	}
}

.service-types__wrapper {
	@extend %content-size;
	.transport-types {
		padding-top: 18px;
	}
	.transport-types__tabs {
		display: table;
		width: 100%;
		li {
			display: table-cell;
			color: $black;
			text-align: center;
			text-transform: uppercase;
			cursor: pointer;
			padding: 10px 12px 6px;
			vertical-align: middle;
			transition: $main;
			&.active {
				background: #e2e2e2;
			}
			span {
				display: inline-block;
				font: 14px/18px $arimoB;
			}
			&:hover {
				background: #e2e2e2;
			}
		}
	}
	.transport-types__content {
		display: none;
		background: #e2e2e2;
		padding: 8px 20px 14px 8px;
		&.active {
			display: block;
		}
		.content__image {
			width: 372px;
			height: 220px;
			float: left;
			background-position: 50% 50%;
			background-size: cover;
			background-repeat: no-repeat;
			overflow: hidden;
		}
		.content__text {
			overflow: hidden;
			padding-left: 20px;
			h1 {
				font: 22px $arimoB;
				color: $blue;
				text-transform: uppercase;
				padding: 6px 0;
			}
			p {
				font: 16px/20px $arimo;
				text-indent: 20px;
				text-align: justify;
				margin-bottom: 11px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		@extend %clearfix;
	}
}

.service-types {
	padding-top: 59px;
	padding-bottom: 50px;
	.section__headings {
		padding-bottom: 30px;
	}
	ul {
		// display: table;
		// width: 100%;
		margin-left: -20px;
		li {
			// display: table-cell;
			// vertical-align: top;
			width: 20%;
			float: left;
			text-align: center;
			padding-left: 20px;
			&:nth-of-type(2) {
				width: 19%;
			}
			&:nth-of-type(3) {
				width: 19%;
			}
			&:nth-of-type(4) {
				width: 18%;
			}
			&:nth-of-type(5) {
				width: 24%;
			}
			&:nth-of-type(5n+1) {
				clear: both;
			}
			.service-types__img {
				display: block;
				line-height: 61px;
				svg {
					display: inline-block;
					vertical-align: middle;
				}
			}
			h2 {
				font: 18.17px/19.57px $arimoB;
				color: $black;
				text-transform: uppercase;
				padding: 5px 0 2px 0;
			}
			p {
				font: 14px/19.57px $arimo;
				color: $black;
			}
		}
		@extend %clearfix;
	}
}

.notification-trigger {
	background: $blue;
	color: #fff;
	> div {
		position: relative;
		@extend %content-size;
		min-height: 151px;
		padding-top: 13px;
		padding-bottom: 27px;
		h1 {
			width: 80%;
			font: 35.81px/38.56px $arimoB;
			text-transform: uppercase;
		}
		img {
			position: absolute;
			bottom: 0;
			right: 85px;
			width: 13.5%;
		}
	}
}

.auto__extra-info {
	@extend %content-size;
	padding-top: 51px;
	padding-bottom: 42px;
	h1 {
		padding-bottom: 31px;
	}
	.extra-info {
		.extra-info__img {
			width: 380px;
			float: left;
			background-repeat: no-repeat;
			background-position: 0 0;
			background-size: cover;
			&.auto {
				height: 290px;
			}
			&.avia {
				height: 240px;
			}
			&.multi {
				height: 290px;
			}
			&.railway {
				height: 450px;
				background-position: right 0;
			}
		}
		.extra-info__text {
			position: relative;
			top: -3px;
			font: 16px/20px $arimo;
			padding: 0 20px 0 21px;
			overflow: hidden;
			ul {
				list-style-type: disc;
				padding-left: 20px;
				li {
					padding-bottom: 10px;
				}
			}
			p {
				display: block;
				// font: 16px/20px $arimo;
				text-indent: 20px;
				text-align: justify;
				margin-bottom: 10px;
			}
		}
		@extend %clearfix;
	}
}

.more-services__wrapper {
	@extend %content-size;
	padding-top: 20px;
	padding-bottom: 60px;
	ul {
		margin-right: -20px;
		li {
			width: 33.33333%;
			float: left;
			padding-right: 20px;
		}
		div {
			text-align: center;
			span {
				display: inline-block;
				line-height: 60px;
				padding-bottom: 16px;
				svg {
					vertical-align: bottom;
				}
			}
			a {
				display: block;
				width: 100%;
				font: 12px/14px $arimoB;
				color: #fff;
				border-radius: 30px;
				text-transform: uppercase;
				background: $red;
				padding: 8px 0;
				transition: $main;
				&:hover {
					background: #dc090c;
				}
			}
		}
		@extend %clearfix;
	}
}
///// END OF INSIDE PAGE STYLES /////


///// VACANCY PAGE STYLES /////
.vacancy__wrapper {
	@extend %content-size;
	min-height: 500px;
	padding-top: 113px;
	padding-bottom: 50px;
	text-align: center;
	font: 14px/16px $arimo;
	h2 {
		font: 18px/24px $arimoB;
	}
	.top-text {
		font-size: 16px;
		line-height: normal;
		padding-bottom: 30px;
	}
	ul {
		display: block;
		max-width: 50%;
		font-size: 16px;
		line-height: normal;
		list-style-type: disc;
		text-align: left;
		margin: 0 auto;
	}
	.find {
		h1 {
			color: $blue;
			font: 32px/1 $arimoB;
			padding-bottom: 20px;
		}
		h2 {
			padding-bottom: 10px;
		}
		ul {
			padding-bottom: 30px;
		}
	}
	.bottom-info {
		font-size: 16px;
		line-height: normal;
		padding-top: 20px;
	}
}
///// END OF VACANCY PAGE STYLES /////


///// POPUP STYLES /////
.vex.vex-theme-default {
	.vex-content {
		&.callback {
			width: 462px;
			background: #fff;
			padding: 0;
		}
		.popup__headings {
			background: $blue;
			padding: 30px 20px;
			text-align: center;
			h2 {
				font: 18px/1 $arimoB;
				text-transform: uppercase;
				color: #fff;
			}
		}
		.fields {
			max-width: 347px;
			padding: 30px 40px;
			margin: 0 auto;
		}
		.field {
			position: relative;
			input, textarea {
				display: block;
				width: 100%;
				font: 14px/1 $arimoB;
				text-transform: uppercase;
				border: solid 1px #bdbdbd;
			}
			input {
				height: 40px;
				padding: 0 14px;
				margin-bottom: 25px;
			}
			textarea {
				height: 85px;
				padding: 10px 14px;
			}
			img {
				position: absolute;
				top: 10px;
				left: -30px;
				display: inline-block;
				width: 20px;
			}
		}
		.order {
			display: block;
			width: 100%;
			padding: 15px 20px;
			background: $blue;
			span {
				display: block;
				width: 200px;
				font: 16px/47px $arimoB;
				color: #fff;
				text-transform: uppercase;
				background: transparent;
				border: solid 1px #fff;
				margin: 0 auto;
				&:hover {
					background: #fff;
					color: $blue;
				}
			}
		}
		// h2 {
		// 	font-size: 24px;
		// 	color: #fff;
		// 	background: $blue;
		// 	padding: 37px 303px 33px 32px;
		// }
		// input {
		// 	display: block;
		// 	width: 100%;
		// 	height: 50px;
		// 	background: #fff;
		// 	border: solid $blue 1px;
		// 	border-radius: 0;
		// 	font-size: 15px;
		// 	padding: 0 25px;
		// 	&::-webkit-input-placeholder {
		// 		color: #000;
		// 	}
		// 	&::-moz-placeholder {
		// 		color: #000;
		// 	}
		// 	&:-moz-placeholder {
		// 		color: #000;
		// 	}
		// 	&:-ms-input-placeholder {
		// 		color: #000;
		// 	}
		// 	&.error {
		// 		border-color: red;
		// 	}
		// }
		// .field {
		// 	position: relative;
		// 	margin-bottom: 21px;
		// }
		// .send-data__button {
		// 	width: 100%;
		// 	font: 15px/50px $bold;
		// 	letter-spacing: 1.5px;
		// 	color: #fff;
		// 	text-transform: uppercase;
		// 	border: none;
		// 	background: $green;
		// 	padding: 0;
		// 	// margin-top: 19px;
		// 	&:hover {
		// 		background: #76cd19;
		// 	}
		// }
	}
}
///// END OF POPUP STYLES /////